
import IcadResultsDesktopTable from "@/components/ICAD/IcadResultsDesktopTable.vue";
import IcadIncidentSearch from "@/components/ICAD/IcadIncidentSearch.vue";
import { defineComponent } from "vue";
import { useCadEventStore } from "@/store/icadEvents";
import { useRegionStore } from "@/store/regions";

export default defineComponent({
  name: "Icad",
  components: {
    IcadResultsDesktopTable,
    IcadIncidentSearch,
  },

  setup() {
    // #region data

    const cadEventStore = useCadEventStore();
    const regionStore = useRegionStore();

    cadEventStore.loadOpenEventsFromApi();
    cadEventStore.loadCadEventTypesFromApi();
    regionStore.loadRegionsFromApi();

    document.title = "Incident Reporting";

    // #endregion
  },
});
