
import { computed, defineComponent } from "vue";
import GenericTableHeaderButton from "@/components/GenericTableHeaderButton.vue";
import IcadResultsTableIncident from "@/components/ICAD/IcadResultsTableIncident.vue";
import { CADSORTOPTIONS, SORTBY, useCadEventStore } from "@/store/icadEvents";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useLoadingStateStore } from "@/store/loadingState";

export default defineComponent({
  name: "IcadResultsDesktopTable",
  components: {
    GenericTableHeaderButton,
    IcadResultsTableIncident,
    LoadingSpinner,
  },

  setup() {
    // #region data

    const eventStore = useCadEventStore();
    const loadingStore = useLoadingStateStore();

    const searchResultsString = computed(() => {
      return `Search Results: ${eventStore.events.length} Incidents`;
    });

    const hasResults = computed(() => {
      return eventStore.events.length > 0;
    });

    const isLoading = computed(() => {
      return loadingStore.getEventsScreenLoading;
    });

    // #endregion

    // #region methods

    const getSortDirection = (field: CADSORTOPTIONS) => {
      if (eventStore.selectedSortOption.sortField === field.sortField) {
        return eventStore.selectedSortOption.sortBy;
      }
      return null;
    };

    const sortBy = (fieldClicked: CADSORTOPTIONS) => {
      if (eventStore.selectedSortOption.sortField === fieldClicked.sortField) {
        eventStore.updateFieldToSort(
          eventStore.selectedSortOption.nextOption()
        );
      } else {
        eventStore.updateFieldToSort(fieldClicked);
      }
    };

    const sortByModel = computed({
      // getter
      get() {
        return eventStore.selectedSortOption;
      },
      // setter
      set(newField: CADSORTOPTIONS) {
        eventStore.updateFieldToSort(newField);
      },
    });

    // #endregion

    // #region methods

    const getLabelForSelectOption = (field: CADSORTOPTIONS) => {
      return `${field.sortField} ${field.sortBy === SORTBY.ASC ? "▲" : "▼"}`;
    };

    // #endregion

    return {
      eventStore,
      searchResultsString,
      hasResults,
      isLoading,
      sortByModel,
      defaultSort: CADSORTOPTIONS.DEFAULT,
      cadSortAsc: CADSORTOPTIONS.CAD_ASC,
      cadSortDesc: CADSORTOPTIONS.CAD_DESC,
      typeSortAsc: CADSORTOPTIONS.TYPE_ASC,
      typeSortDesc: CADSORTOPTIONS.TYPE_DESC,
      startSortAsc: CADSORTOPTIONS.START_ASC,
      startSortDesc: CADSORTOPTIONS.START_DESC,
      addressSortAsc: CADSORTOPTIONS.ADDRESS_ASC,
      addressSortDesc: CADSORTOPTIONS.ADDRESS_DESC,
      resultSortAsc: CADSORTOPTIONS.RESULT_ASC,
      resultSortDesc: CADSORTOPTIONS.RESULT_DESC,
      getSortDirection,
      sortBy,
      getLabelForSelectOption,
    };
  },
});
