import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icad-results-table-incident__cad cell col-2 col-md-2" }
const _hoisted_2 = { class: "icad-results-table-incident__type cell d-flex flex-row align-items-center col-2 col-md-2" }
const _hoisted_3 = { class: "cad-type" }
const _hoisted_4 = { class: "icad-results-table-incident__start cell col-2 col-md-2" }
const _hoisted_5 = { class: "icad-results-table-incident__address cell col-3 col-md-3" }
const _hoisted_6 = { class: "icad-results-table-incident__buttons" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IncidentIcon = _resolveComponent("IncidentIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["icad-results-table-incident d-flex flex-row align-items-center row", { open: _ctx.isIncidentOpen }])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_IncidentIcon, {
        type: _ctx.type,
        class: "icon-mobile d-block d-md-none"
      }, null, 8, ["type"]),
      _createElementVNode("p", {
        class: _normalizeClass(["icad-number", { open: _ctx.isIncidentOpen }])
      }, _toDisplayString(_ctx.cadNumber), 3)
    ]),
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_IncidentIcon, {
        type: _ctx.type,
        class: "icon d-block"
      }, null, 8, ["type"]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.type), 1)
    ]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.displayDate), 1),
    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.address), 1),
    _createElementVNode("p", {
      class: _normalizeClass(["icad-results-table-incident__result cell col-2 col-md-2", { open: _ctx.isIncidentOpen }])
    }, _toDisplayString(_ctx.breakOnCommasAndAddSpaces(_ctx.result)), 3),
    _createElementVNode("span", _hoisted_6, [
      _createElementVNode("a", {
        class: "report-button col-1 col-md-1 d-flex align-items-center justify-content-center",
        href: _ctx.reportLink,
        target: "_blank"
      }, _toDisplayString(_ctx.reportButtonLabel), 9, _hoisted_7)
    ])
  ], 2))
}