
import { computed, defineComponent, ref } from "vue";
import IncidentIcon from "@/components/IncidentIcon.vue";
import { formatDateThenTime } from "@/helpers/dateHelpers";
import { generateReportLink } from "@/helpers/actionLinks";
import { breakOnCommasAndAddSpaces } from "@/helpers/stringHelpers";

export default defineComponent({
  name: "IcadResultsTableIncident",
  components: { IncidentIcon },
  props: {
    cadNumber: String,
    type: String,
    startDate: Date,
    address: String,
    result: String,
    state: String,
  },

  setup(props) {
    // #region data

    const reportButtonLabel = ref("Report");

    const reportLink = props.cadNumber
      ? generateReportLink(props.cadNumber)
      : "";

    const displayDate = computed((): string => {
      return formatDateThenTime(props.startDate);
    });

    const isIncidentOpen = computed((): boolean => {
      return props.state === "OPEN" || props.state === "RE-OPENED";
    });

    // #endregion

    return {
      reportButtonLabel,
      displayDate,
      reportLink,
      isIncidentOpen,
      breakOnCommasAndAddSpaces,
    };
  },
});
