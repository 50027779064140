
import { computed, defineComponent, ref } from "vue";
import { useRegionStore } from "@/store/regions";
import { useCadEventStore } from "@/store/icadEvents";
import { ICadEventsListFilter } from "@/data/cadEventsFilter";
import {
  addDaysToCurrentDate,
  convertDateStringToTimestamp,
  formatMonthThenYear,
  getDateByMonthsDifferent,
} from "@/helpers/dateHelpers";
import { generateReportLink } from "@/helpers/actionLinks";
import useBreakpoints from "@/helpers/breakpoint";

export default defineComponent({
  name: "IcadIncidentSearch",
  setup() {
    // #region data

    const regionStore = useRegionStore();
    const eventStore = useCadEventStore();
    const breakpoint = useBreakpoints();

    // initial value to stop open by default on mobile/tablet views
    const isOpen = ref(breakpoint.type.value === "desktop");

    const isLocationOpen = ref(false);
    const isDistrictOpen = ref(false);
    const isDateOpen = ref(false);

    const eventStatusList = [
      { value: "", text: "All" },
      { value: "OPEN", text: "Open" },
      { value: "CLOSED", text: "Closed" },
    ];

    // Data model

    const DATERANGES = {
      DEFAULT: "Select",
      CUSTOM: "Custom date range",
      TODAY: "Today",
      LAST7DAYS: "Last 7 days",
      LAST30DAYS: "Last 30 days",
      LAST90DAYS: "Last 90 days",
      LAST180DAYS: "Last 180 days",
      CURRENTMONTH: "Current month",
      PREVIOUSMONTH: "Previous month",
      MONTHBEFORELAST: "Month before last",
    } as const;
    // eslint-disable-next-line no-redeclare
    type DATERANGES = typeof DATERANGES[keyof typeof DATERANGES];

    const dateSelect = ref(DATERANGES.DEFAULT);

    const startOfDay = false;

    const startOfCurrentMonth = getDateByMonthsDifferent(0, 1, startOfDay);
    const previousMonthStartDate = getDateByMonthsDifferent(-1, 1, startOfDay);
    const monthBeforeLastStartDate = getDateByMonthsDifferent(
      -2,
      1,
      startOfDay
    );

    // Formatted display "Month Year" date strings for dropdown
    const currentMonthDisplayText = computed((): string => {
      return formatMonthThenYear(startOfCurrentMonth);
    });
    const previousMonthDisplayText = computed((): string => {
      return formatMonthThenYear(previousMonthStartDate);
    });
    const monthBeforeLastDisplayText = computed((): string => {
      return formatMonthThenYear(monthBeforeLastStartDate);
    });

    const dateRangeOptions = [
      { value: DATERANGES.CUSTOM, text: DATERANGES.CUSTOM },
      { value: DATERANGES.TODAY, text: DATERANGES.TODAY },
      { value: DATERANGES.LAST7DAYS, text: DATERANGES.LAST7DAYS },
      { value: DATERANGES.LAST30DAYS, text: DATERANGES.LAST30DAYS },
      { value: DATERANGES.LAST90DAYS, text: DATERANGES.LAST90DAYS },
      { value: DATERANGES.LAST180DAYS, text: DATERANGES.LAST180DAYS },
      {
        value: DATERANGES.CURRENTMONTH,
        text: currentMonthDisplayText.value,
      },
      {
        value: DATERANGES.PREVIOUSMONTH,
        text: previousMonthDisplayText.value,
      },
      {
        value: DATERANGES.MONTHBEFORELAST,
        text: monthBeforeLastDisplayText.value,
      },
    ];
    const formatSearchDate = (date?: Date): string | undefined => {
      if (!date) return date;
      return date
        .toLocaleString("en-nz")
        .split(",")[0]
        .split("/")
        .reverse()
        .map((str) => str.padStart(2, "0"))
        .join("-");
    };

    const customBegin = computed<string | undefined>({
      get() {
        return formatSearchDate(eventStore.filters.begin);
      },
      set(dateSelected: any) {
        if (dateSelected) {
          eventStore.filters.begin = convertDateStringToTimestamp(
            dateSelected,
            false
          );
        }
      },
    });

    const customEnd = computed<string | undefined>({
      get() {
        return formatSearchDate(eventStore.filters.end);
      },
      set(dateSelected: any) {
        if (dateSelected) {
          eventStore.filters.end = convertDateStringToTimestamp(
            dateSelected,
            true
          );
        }
      },
    });

    // #endregion

    // #region methods

    const toggleMenu = () => {
      isOpen.value = !isOpen.value;
    };

    const handleRegionSelected = () => {
      // if empty region val or different than previously selected
      if (eventStore.filters.locationLevel1 === "") {
        // Close and reset district & brigade selections
        eventStore.previousSelectedRegion = eventStore.filters.locationLevel1;
        isLocationOpen.value = false;
        isDistrictOpen.value = false;
        eventStore.filters.locationLevel2 = "";
        eventStore.filters.station = "";
      } else if (
        eventStore.previousSelectedRegion !== "" &&
        eventStore.previousSelectedRegion != eventStore.filters.locationLevel1
      ) {
        // If region value changes but is not empty,
        // reset disctrict and station vals, close station
        if (eventStore.filters.locationLevel1) {
          eventStore.previousSelectedRegion = eventStore.filters.locationLevel1;
        }
        isDistrictOpen.value = false;
        eventStore.filters.locationLevel2 = "";
        eventStore.filters.station = "";
      } else {
        isLocationOpen.value = true;
        if (eventStore.filters.locationLevel1) {
          eventStore.previousSelectedRegion = eventStore.filters.locationLevel1;
        }
      }
    };

    const handleDistrictSelected = () => {
      if (eventStore.filters.locationLevel2 === "") {
        // Close and reset brigade selection
        isDistrictOpen.value = false;
        eventStore.filters.station = "";
      } else {
        // Always reset brigade val on district open
        // (resets available station selections and default to "select")
        isDistrictOpen.value = true;
        eventStore.filters.station = "";
      }
    };

    const handleDateSelected = () => {
      if (dateSelect.value == DATERANGES.DEFAULT) {
        isDateOpen.value = false;
        eventStore.filters.end = undefined;
        eventStore.filters.begin = undefined;
      } else if (dateSelect.value == DATERANGES.CUSTOM) {
        isDateOpen.value = true;
        // reset begin/end to undefined in case only begin OR end date are selected
        eventStore.filters.end = undefined;
        eventStore.filters.begin = undefined;
      } else if (dateSelect.value == DATERANGES.TODAY) {
        isDateOpen.value = false;
        eventStore.filters.begin = new Date(new Date().setHours(0, 0, 0, 0));
        eventStore.filters.end = undefined;
      } else if (dateSelect.value == DATERANGES.LAST7DAYS) {
        isDateOpen.value = false;
        const last7Days = ref(addDaysToCurrentDate(-7));
        eventStore.filters.begin = last7Days.value;
        eventStore.filters.end = undefined;
      } else if (dateSelect.value == DATERANGES.LAST30DAYS) {
        isDateOpen.value = false;
        const last30Days = ref(addDaysToCurrentDate(-30));
        eventStore.filters.begin = last30Days.value;
        eventStore.filters.end = undefined;
      } else if (dateSelect.value == DATERANGES.LAST90DAYS) {
        isDateOpen.value = false;
        const last90Days = ref(addDaysToCurrentDate(-90));
        eventStore.filters.begin = last90Days.value;
        eventStore.filters.end = undefined;
      } else if (dateSelect.value == DATERANGES.LAST180DAYS) {
        isDateOpen.value = false;
        const last180Days = ref(addDaysToCurrentDate(-180));
        eventStore.filters.begin = last180Days.value;
        eventStore.filters.end = undefined;
      } else if (dateSelect.value == DATERANGES.CURRENTMONTH) {
        isDateOpen.value = false;
        eventStore.filters.begin = getDateByMonthsDifferent(0, 1, false);
        eventStore.filters.end = undefined;
      } else if (dateSelect.value == DATERANGES.PREVIOUSMONTH) {
        isDateOpen.value = false;
        eventStore.filters.begin = getDateByMonthsDifferent(-1, 1, false);
        eventStore.filters.end = getDateByMonthsDifferent(0, 0, true);
      } else if (dateSelect.value == DATERANGES.MONTHBEFORELAST) {
        isDateOpen.value = false;
        eventStore.filters.begin = getDateByMonthsDifferent(-2, 1, false);
        eventStore.filters.end = getDateByMonthsDifferent(-1, 0, true);
      }
    };

    const handleSubmit = async (event: any) => {
      event.preventDefault();
      await eventStore.loadFilteredEventsFromApi();
      if (eventStore.events.length === 1) {
        const reportLink = generateReportLink(eventStore.events[0].cadNumber);
        window.open(reportLink, "_blank");
        // reset filter values after submit
        handleReset(event);
      }
    };

    const handleReset = (event: any) => {
      event.preventDefault();
      // reset state filters
      dateSelect.value = DATERANGES.DEFAULT;
      eventStore.filters = {
        cadNumber: "",
        locationLevel1: "",
        locationLevel2: "",
        station: "",
        dateSelect: "",
        begin: undefined,
        end: undefined,
        type: "",
        state: "",
        callSign: "",
      } as ICadEventsListFilter;
      isLocationOpen.value = false;
      isDistrictOpen.value = false;
    };

    // #endregion

    return {
      isOpen,
      isLocationOpen,
      isDistrictOpen,
      isDateOpen,
      dateSelect,
      eventStore,
      regionStore,
      eventStatusList,
      customBegin,
      customEnd,
      DATERANGES,
      dateRangeOptions,
      toggleMenu,
      handleRegionSelected,
      handleDistrictSelected,
      handleDateSelected,
      handleSubmit,
      handleReset,
    };
  },
});
