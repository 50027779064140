import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icad row" }
const _hoisted_2 = { class: "icad__filters-container col-12 col-xl-3" }
const _hoisted_3 = { class: "icad__container col-12 col-xl-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IcadIncidentSearch = _resolveComponent("IcadIncidentSearch")!
  const _component_IcadResultsDesktopTable = _resolveComponent("IcadResultsDesktopTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IcadIncidentSearch)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_IcadResultsDesktopTable, { class: "d-block" })
    ])
  ]))
}